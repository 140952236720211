import { useEffect, useState } from "react";
import {
  Dataset_standard_acc,
  Dataset_plus_acc,
} from "../helper/liveRatesData";

export const useLivePrices = (formValue, accountType) => {
  const [priceData, setPricedata] = useState({});

  useEffect(() => {
    if (accountType === "plus") {
      setPricedata(Dataset_plus_acc());
    } else if (accountType === "standard") {
      setPricedata(Dataset_standard_acc());
    }
  }, [accountType]);

  if (!priceData) {
    console.error("No price data available");
    return { ask: 0, bid: 0, spread: 0 }; // Handle the case when there's no data
  }

  let liveRates = priceData[formValue.assetClass];
  let ask = 0;
  let bid = 0;
  let spread = 0;

  if (liveRates) {
    if (liveRates[formValue.symbol]) {
      ask = liveRates[formValue.symbol][1];
      bid = liveRates[formValue.symbol][0];
      spread = parseFloat((ask - bid).toFixed(15));
    }
  }

  return { ask, bid, spread };
};
