import { formatValueReplace } from "./formatValues";
import { Instruments } from "./instruments";

export const CalculateVolPerLot = (symbol, lot) => {
  if (symbol) {
    var filterInstrument = Instruments().filter(
      (value) => value.symbol === symbol
    );
  }
  if (typeof lot === "string" || lot === isNaN) {
    lot = formatValueReplace(lot);
  }

  return filterInstrument ? filterInstrument[0]?.lotSize * lot : 0;
};
