import React, { useEffect, useState } from "react";
import { useLivePrices } from "../hooks/useLivePrices";

export default function LiveRates({ formValue }) {
  const [prices, setPrices] = useState({ ask: 0, bid: 0 });
  const priceData = useLivePrices(formValue, formValue.accountType);
  useEffect(() => {
    const fetchPrices = async () => {
      // const priceData = await LivePrices(formValue);
      setPrices({ ask: priceData.ask, bid: priceData.bid });
    };

    if (formValue.symbol) {
      fetchPrices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValue.symbol,
    formValue.assetClass,
    formValue.accountType,
    priceData.ask,
  ]);

  return (
    <div className="flex justify-between rounded-md w-full md:max-w-[282px] px-2 live_rates">
      <div className="badge">
        BID
        <span className="bg-[#FFEBEC] text-[#F23645] ms-2 px-2.5 py-0.5 rounded">
          {prices.bid}
        </span>
      </div>

      <div className="badge">
        ASK
        <span className="ms-2 px-2.5 py-0.5 rounded">{prices.ask}</span>
      </div>
    </div>
  );
}
