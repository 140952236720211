import CustomGrid from "./components/customGrid";
import CustomInput from "./components/customInput";
import CustomRadio from "./components/customRadio";
import CustomSelect from "./components/cutomSelect";
import LiveRates from "./components/liveRates";
import { AssetClasses } from "./helper/assetClasses";
import { CalculateRebate } from "./helper/calculateRebate";
import { Instruments } from "./helper/instruments";
import { useAppContext } from "./context/appContext";
import CustomLabel from "./components/customLabel";
import { Tiers } from "./helper/tiers";

function Calculator() {
  const { formValue, handleFieldChange } = useAppContext();

  return (
    <div className="bg-[#FFFFFF] max-w-[761px] w-100 app">
      <div className="block md:flex items-center justify-between md:justify-center">
        <span className="w-max text-[#0bab47] me-5">Indicative prices</span>
        <LiveRates formValue={formValue} />
      </div>
      <CustomGrid cols={"md:grid-cols-4"}>
        <div className="flex md:col-span-2">
          <CustomRadio
            name={"accountType"}
            id={"plus"}
            value={"plus"}
            checkedValue={formValue.accountType === "plus"}
            handleChange={handleFieldChange}
            label={"GO Plus+ Account"}
          />
          <CustomRadio
            name={"accountType"}
            id={"standard"}
            value={"standard"}
            checkedValue={formValue.accountType === "standard"}
            handleChange={handleFieldChange}
            label={"Standard Account"}
          />
        </div>
        <div className="text-start">
          <CustomLabel label={"Asset Class"} />
          <CustomSelect
            value={formValue.assetClass}
            handleChange={handleFieldChange}
            id={"asset-class"}
            label={"Asset Class"}
            name={"assetClass"}
            options={AssetClasses()}
          />
        </div>
        <div className="text-start">
          <CustomLabel label={"Symbol"} />
          <CustomSelect
            value={formValue.symbol}
            handleChange={handleFieldChange}
            id={"symbol"}
            label={"Symbol"}
            name={"symbol"}
            options={Instruments().filter(
              (assetClass) =>
                assetClass.Asset_Group.toUpperCase() === formValue.assetClass
            )}
          />
        </div>
      </CustomGrid>

      <CustomGrid cols={"md:grid-cols-2"}>
        <div className="text-start">
          <CustomLabel label={"Lots"} />
          <CustomInput
            type={"text"}
            id={"lots"}
            name={"lots"}
            placeholder={"Lots"}
            value={formValue.lots ? formValue.lots.toLocaleString() : ""}
            label={"Lots"}
            handleChange={handleFieldChange}
          />
        </div>
        <div className="text-start">
          <CustomLabel label={"Monthly Notional Traded Volume (USD)"} />
          <CustomInput
            type={"text"}
            id={"volume"}
            name={"volume"}
            placeholder={"Volume"}
            value={formValue.volume ? formValue.volume.toLocaleString() : ""}
            handleChange={handleFieldChange}
            label={"Monthly Notional Traded Volume (USD)"}
            itemBefore={"$"}
          />
        </div>
      </CustomGrid>

      <CustomGrid cols={1}>
        <div className="text-start">
          <CustomLabel label={"Estimated Trading Cost"} />
          <CustomInput
            type={"text"}
            id={"tradingTotal"}
            name={"tradingTotal"}
            placeholder={"Est. trading cost"}
            value={
              formValue.tradingTotal
                ? parseFloat(formValue.tradingTotal).toLocaleString()
                : ""
            }
            label={"Estimated Trading Cost"}
            disabled={true}
            itemBefore={"$"}
          />
        </div>
      </CustomGrid>

      {/*  <CustomGrid cols={"md:grid-cols-2"}>
        <div className="text-start">
          <CustomLabel label={"Spread"} />
          <CustomInput
            type={"text"}
            id={"spread"}
            name={"spread"}
            placeholder={"Spread"}
            value={
              formValue.spread
                ? formatValue(
                    parseFloat(formValue.spread).toLocaleString(undefined, {
                      minimumFractionDigits: 5,
                      maximumFractionDigits: 5,
                    })
                  )
                : ""
            }
            label={"Spread"}
            disabled={true}
            itemBefore={"$"}
          />
        </div>
        <div className="text-start">
          <CustomLabel label={"Commission"} />
          <CustomInput
            type={"text"}
            id={"commission"}
            name={"commission"}
            placeholder={"Commission"}
            value={
              formValue.commission
                ? parseFloat(formValue.commission).toLocaleString()
                : ""
            }
            label={"Commission"}
            disabled={true}
            itemBefore={"$"}
          />
        </div>
      </CustomGrid> */}
      <CustomGrid cols={"md:grid-cols-2"}>
        <div className="text-start">
          <CustomLabel label={"Rebate Tier"} />
          {!formValue.editTier ? (
            <CustomInput
              type={"number"}
              id={"rebateTier"}
              name={"rebateTier"}
              placeholder={"Rebate Tier"}
              value={formValue.rebateTier ? formValue.rebateTier : ""}
              label={"Rebate Tier"}
              itemBefore={formValue.rebateTier && "Tier"}
              disabled={true}
            />
          ) : (
            <CustomSelect
              value={formValue.rebateTier}
              handleChange={handleFieldChange}
              id={"rebate-tier"}
              label={"Rebate Tier"}
              name={"rebateTier"}
              options={Tiers()}
            />
          )}
        </div>
        <div className="text-start">
          <CustomLabel label={"Rebate Amount (%)"} />
          <CustomInput
            type={"text"}
            id={"rebateAmount"}
            name={"rebateAmount"}
            placeholder={"Rebate Amount"}
            value={`${parseInt(formValue.rebateAmount)}%`}
            label={"Rebate Amount (%)"}
            disabled={true}
          />
        </div>
      </CustomGrid>
      <CustomGrid cols={1}>
        <div className="text-center">
          <CustomLabel label={"Rebate Amount (%)"} />
          <div className="rounded-md total_container p-2 border border-[#BECAD6] md:border-[#E2E5EC]">
            <span className="hidden md:inline ">
              Estimated Rebate Amount ($)
            </span>
            <div className="text-[#0BAB47] mt-1">
              $
              {parseFloat(
                CalculateRebate(
                  parseFloat(formValue.spread),
                  formValue.commission,
                  formValue.rebateAmount
                )
              ).toLocaleString()}
            </div>
          </div>
        </div>
      </CustomGrid>
    </div>
  );
}

export default Calculator;
