import { useState } from "react";

//input handler
export function useHandleChange(initialValue) {
  const [formValue, setFormValue] = useState(initialValue);

  const handleChange = (e) => {
    let targetValue = e.target.value;

    if (e.target.type === "checkbox") {
      targetValue = e.target.checked;
    }

    setFormValue({ ...formValue, [e.target.name]: targetValue });
  };

  return {
    formValue,
    setFormValue,
    handleFieldChange: handleChange,
  };
}
