export const Instruments = () => {
  return [
    {
      symbol: "ADAUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 4,
      spread: 0.0025,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "ASX200",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 1.3,
      lastPrice: 7623.0,
      lotSize: 5097.91,
      commission: 0.0,
    },
    {
      symbol: "AUDCAD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000074,
      lastPrice: 1.0,
      lotSize: 66875.4,
      commission: 3.0,
    },
    {
      symbol: "AUDCHF",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000104,
      lastPrice: 1.0,
      lotSize: 66875.4,
      commission: 3.0,
    },
    {
      symbol: "AUDCNH",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000421,
      lastPrice: 1.0,
      lotSize: 66875.4,
      commission: 3.0,
    },
    {
      symbol: "AUDHKD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000344,
      lastPrice: 1.0,
      lotSize: 66875.4,
      commission: 3.0,
    },
    {
      symbol: "AUDJPY",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 3,
      spread: 0.010097,
      lastPrice: 1.0,
      lotSize: 66875.4,
      commission: 3.0,
    },
    {
      symbol: "AUDNZD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000161,
      lastPrice: 1.0,
      lotSize: 66875.4,
      commission: 3.0,
    },
    {
      symbol: "AUDSGD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.00021,
      lastPrice: 1.0,
      lotSize: 66875.4,
      commission: 3.0,
    },
    {
      symbol: "AUDUSD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000066,
      lastPrice: 1.0,
      lotSize: 66875.4,
      commission: 3.0,
    },
    {
      symbol: "BCHUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 2,
      spread: 1.164082,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "BTCUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 2,
      spread: 41.776098,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "CAC40",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 1.0,
      lastPrice: 7938.0,
      lotSize: 8682.77,
      commission: 0.0,
    },
    {
      symbol: "CADCHF",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.00013,
      lastPrice: 1.0,
      lotSize: 72785.5,
      commission: 3.0,
    },
    {
      symbol: "CADJPY",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 3,
      spread: 0.013,
      lastPrice: 1.0,
      lotSize: 72785.5,
      commission: 3.0,
    },
    {
      symbol: "CHFJPY",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 3,
      spread: 0.022816,
      lastPrice: 1.0,
      lotSize: 116324.3,
      commission: 3.0,
    },
    {
      symbol: "CHINA50",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 10.0,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "COPPER-F",
      Asset_Group: "Commodities Futures",
      group: "Commodities",
      digits: 4,
      spread: 0.003,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "DAX40",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 1.097714,
      lastPrice: 1.0,
      lotSize: 1.09,
      commission: 0.0,
    },
    {
      symbol: "DGEUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 5,
      spread: 0.00061,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "DSHUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 2,
      spread: 0.35,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "EOSUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 3,
      spread: 0.007385,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "ESP35",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 5.0,
      lastPrice: 1.0,
      lotSize: 1.09,
      commission: 0.0,
    },
    {
      symbol: "ETHUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 2,
      spread: 3.899979,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "EURAUD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000141,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "EURBND-F",
      Asset_Group: "Bonds Futures",
      group: "Bonds",
      digits: 3,
      spread: 0.028,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "EURCAD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000088,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURCHF",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000085,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURDKK",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000186,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURGBP",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000043,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURHUF",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 3,
      spread: 0.187,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURJPY",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 3,
      spread: 0.010809,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURMXN",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 4,
      spread: 0.00237,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURNZD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000191,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURPLN",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.00132,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURSEK",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.00174,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURSGD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000417,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "EURUSD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000053,
      lastPrice: 1.0,
      lotSize: 109382.3,
      commission: 3.0,
    },
    {
      symbol: "FTSE100",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 1.389755,
      lastPrice: 1.0,
      lotSize: 1.31,
      commission: 0.0,
    },
    {
      symbol: "GBPAUD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000204,
      lastPrice: 1.0,
      lotSize: 130667.5,
      commission: 3.0,
    },
    {
      symbol: "GBPCAD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000176,
      lastPrice: 1.0,
      lotSize: 130667.5,
      commission: 3.0,
    },
    {
      symbol: "GBPCHF",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.00013,
      lastPrice: 1.0,
      lotSize: 130667.5,
      commission: 3.0,
    },
    {
      symbol: "GBPJPY",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 3,
      spread: 0.025454,
      lastPrice: 1.0,
      lotSize: 130667.5,
      commission: 3.0,
    },
    {
      symbol: "GBPNZD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000351,
      lastPrice: 1.0,
      lotSize: 130667.5,
      commission: 3.0,
    },
    {
      symbol: "GBPUSD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000055,
      lastPrice: 1.0,
      lotSize: 130667.5,
      commission: 3.0,
    },
    {
      symbol: "HK50",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 5.254484,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "JGB-F",
      Asset_Group: "Bonds Futures",
      group: "Bonds",
      digits: 3,
      spread: 0.07,
      lastPrice: 1.0,
      lotSize: 0.13,
      commission: 0.0,
    },
    {
      symbol: "JP225",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 8.277564,
      lastPrice: 1.0,
      lotSize: 0.01,
      commission: 0.0,
    },
    {
      symbol: "LNKUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 3,
      spread: 0.07,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "LTCUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 2,
      spread: 0.140769,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "MTCUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 5,
      spread: 0.00416,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "NDX100",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 0.707828,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "NGAS",
      Asset_Group: "Commodities",
      group: "Commodities",
      digits: 3,
      spread: 0.013585,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "NZDCAD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000155,
      lastPrice: 1.0,
      lotSize: 60921.8,
      commission: 3.0,
    },
    {
      symbol: "NZDCHF",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000117,
      lastPrice: 1.0,
      lotSize: 60921.8,
      commission: 3.0,
    },
    {
      symbol: "NZDJPY",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 3,
      spread: 0.019415,
      lastPrice: 1.0,
      lotSize: 60921.8,
      commission: 3.0,
    },
    {
      symbol: "NZDUSD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000053,
      lastPrice: 1.0,
      lotSize: 60921.8,
      commission: 3.0,
    },
    {
      symbol: "SBEAN-F",
      Asset_Group: "Commodities Futures",
      group: "Commodities",
      digits: 4,
      spread: 0.018,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "SGDJPY",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 3,
      spread: 0.020991,
      lastPrice: 1.0,
      lotSize: 76303.4,
      commission: 3.0,
    },
    {
      symbol: "SOLUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 3,
      spread: 0.33615,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "STOXX50",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 1.6,
      lastPrice: 1.0,
      lotSize: 1.09,
      commission: 0.0,
    },
    {
      symbol: "TRXUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 5,
      spread: 0.0005,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "UKGB-F",
      Asset_Group: "Bonds Futures",
      group: "Bonds",
      digits: 3,
      spread: 0.035,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "UKOil-F",
      Asset_Group: "Commodities Futures",
      group: "Commodities",
      digits: 3,
      spread: 0.035128,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "UKOUSD",
      Asset_Group: "Commodities",
      group: "Commodities",
      digits: 3,
      spread: 0.029764,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "US2000",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 0.607619,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "US500",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 0.610302,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
    {
      symbol: "USDCAD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000074,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDCHF",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000077,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDCNH",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000535,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDDKK",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000478,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDHKD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000146,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDJPY",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 3,
      spread: 0.010272,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDMXN",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.002014,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDNOK",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.002657,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDOLLAR",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 3,
      spread: 0.03,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "USDSEK",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.002097,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDSGD",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.000134,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USDZAR",
      Asset_Group: "Forex",
      group: "Forex",
      digits: 5,
      spread: 0.00623,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 3.0,
    },
    {
      symbol: "USOil-F",
      Asset_Group: "Commodities Futures",
      group: "Commodities",
      digits: 3,
      spread: 0.035052,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "USOUSD",
      Asset_Group: "Commodities",
      group: "Commodities",
      digits: 3,
      spread: 0.028555,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "UST10Y-F",
      Asset_Group: "Bonds Futures",
      group: "Bonds",
      digits: 3,
      spread: 0.04,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "UST5Y-F",
      Asset_Group: "Bonds Futures",
      group: "Bonds",
      digits: 3,
      spread: 0.02,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "VIX",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 0.090359,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "VIX-F",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 3,
      spread: 0.08,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "WHEAT-F",
      Asset_Group: "Commodities Futures",
      group: "Commodities",
      digits: 4,
      spread: 0.01,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "WS30",
      Asset_Group: "Indices",
      group: "Indices",
      digits: 2,
      spread: 1.837096,
      lastPrice: 1.0,
      lotSize: 1.0,
      commission: 0.0,
    },
    {
      symbol: "XLMUSD",
      Asset_Group: "Crypto",
      group: "Crypto",
      digits: 4,
      spread: 0.000833,
      lastPrice: 1.0,
      lotSize: 100000.0,
      commission: 0.0,
    },
  ];
};
