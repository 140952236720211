import React, { createContext, useContext, useEffect } from "react";
import { CalculateRebatePer } from "../helper/calculateRebatePer";
import { useHandleChange } from "../hooks/handleChange";
import { CalculateLots } from "../helper/calculateLots";
import { CalculateCommission } from "../helper/calculateCommission";
import { CalculateSpread } from "../helper/calculateSpread";
import { CalculateTier } from "../helper/calculateTier";
import { useLivePrices } from "../hooks/useLivePrices";
import { CalculateVolPerLot } from "../helper/calculateVolPerLot";

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const { formValue, setFormValue, handleFieldChange } = useHandleChange({
    spread: 0,
    accountType: "plus",
    assetClass: "",
    symbol: "",
    volume: 0,
    lots: 0,
    rebateTier: "",
    rebateAmount: 0,
    commission: 0,
    tradingTotal: 0,
  });
  const prices = useLivePrices(formValue, formValue.accountType);

  useEffect(() => {
    const fetchAndUpdateValues = () => {
      let updatedValues = { ...formValue };

      /*  setInterval(async () => {
        if (formValue.symbol) {
          const prices = await LivePrices(formValue);
          updatedValues.spread = prices.spread;
        }
      }, 300000); */

      if (formValue.symbol) {
        updatedValues.spread = prices.spread;
      }

      const rebateAmount = CalculateRebatePer(
        formValue.assetClass,
        formValue.rebateTier
      );
      if (updatedValues.rebateAmount !== rebateAmount) {
        updatedValues.rebateAmount = rebateAmount;
      }

      /*   const lots = CalculateLots(formValue.volume, formValue.symbol);
      if (updatedValues.lots !== lots && formValue.volume) {
        updatedValues.lots = lots;
      } */

      const commission = CalculateCommission(
        formValue.lots,
        formValue.symbol,
        formValue.accountType
      );
      if (updatedValues.commission !== commission) {
        updatedValues.commission = commission;
      }

      const spread = CalculateSpread(
        formValue.symbol,
        formValue.volume,
        prices.spread
      );
      if (formValue.symbol && formValue.volume) {
        if (updatedValues.spread !== spread) {
          updatedValues.spread = spread;
        }
      }

      const tradingTotal = spread + commission;
      if (formValue.spread || formValue.commission) {
        if (updatedValues.tradingTotal !== tradingTotal) {
          updatedValues.tradingTotal = tradingTotal.toFixed(2);
        }
      }

      const rebateTier = CalculateTier(formValue.volume, formValue.assetClass);
      if (formValue.assetClass && formValue.volume) {
        if (updatedValues.rebateTier !== rebateTier) {
          updatedValues.rebateTier = rebateTier;
        }
      }

      setFormValue(updatedValues);
    };

    fetchAndUpdateValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formValue.symbol,
    formValue.assetClass,
    formValue.rebateTier,
    formValue.volume,
    // formValue.lots,
    formValue.accountType,
    prices.spread,
  ]);

  useEffect(() => {
    const lots = CalculateLots(formValue.volume, formValue.symbol);
    if (formValue.lots !== lots && formValue.volume) {
      setFormValue((prev) => ({
        ...prev,
        lots: lots,
      }));
    } else if (!formValue.volume) {
      setFormValue((prev) => ({
        ...prev,
        lots: 0,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.volume]);

  //update vol traded if lots changed
  useEffect(() => {
    const volPerLot = CalculateVolPerLot(formValue.symbol, formValue.lots);
    if (formValue.volume !== volPerLot && formValue.lots) {
      setFormValue((prev) => ({
        ...prev,
        volume: volPerLot,
      }));
    } else if (!formValue.lots) {
      setFormValue((prev) => ({
        ...prev,
        volume: 0,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.lots, formValue.symbol]);

  useEffect(() => {
    if (formValue.assetClass) {
      setFormValue((prev) => ({
        ...prev,
        spread: 0,
        symbol: "",
        lots: 0,
        rebateAmount: 0,
        commission: 0,
        volume: 0,
        tradingTotal: 0,
      }));
    }
  }, [formValue.assetClass, setFormValue]);

  return (
    <AppContext.Provider value={{ formValue, handleFieldChange }}>
      {children}
    </AppContext.Provider>
  );
};
