import { formatValueReplace } from "./formatValues";
import { Instruments } from "./instruments";

export const CalculateLots = (volume, symbol) => {
  if (symbol) {
    var filterInstrument = Instruments().filter(
      (value) => value.symbol === symbol
    );
  }

  if (typeof volume === "string" || volume === isNaN) {
    volume = formatValueReplace(volume);
  }

  return volume && filterInstrument ? volume / filterInstrument[0]?.lotSize : 0;
};
