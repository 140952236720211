import { Instruments } from "./instruments";

export const CalculateSpread = (symbol, volume, spread) => {
  var filterInstrument = Instruments().filter(
    (value) => value.symbol === symbol
  );

  /*  return (
    (filterInstrument[0]?.spread *
      (parseInt(volume) / filterInstrument[0]?.lastPrice)) /
    2
  ); */

  return (spread * (parseInt(volume) / filterInstrument[0]?.lastPrice)) / 2;
};
