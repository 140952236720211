import React, { useEffect } from "react";

export default function CustomInput({
  type,
  id,
  name,
  placeholder,
  value,
  handleChange,
  label,
  disabled,
  customStyle,
  labelStyle,
  itemBefore,
}) {
  const handleWheel = (e) => {
    if (e.target.type === "number") {
      e.preventDefault(); // Prevents scroll behavior for number input
    }
  };

  useEffect(() => {
    const inputElement = document.getElementById(id);
    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel, { passive: false });
      return () => {
        inputElement.removeEventListener("wheel", handleWheel);
      };
    }
  }, [id]);
  return (
    <div
      className={`custom-input border border-[#BECAD6] md:border-[#E2E5EC] rounded-md w-full p-1.5 text-start ${
        disabled ? "bg-[#E2E5EC]" : ""
      } ${customStyle}`}
    >
      <span className={`p-2 ${labelStyle} hidden md:block truncate`}>
        {label}
      </span>
      <div className="flex items-center h-full md:h-full">
        {itemBefore && <div className="ml-2">{itemBefore}</div>}
        <input
          disabled={disabled}
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className="block w-full p-2.5 !outline-none bg-transparent"
        />
      </div>
    </div>
  );
}
