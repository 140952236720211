export function formatValue(value) {
  value = value.replace(/,/g, "");
  value = parseFloat(value);

  if (value % 1 === 0 || value > 0.9) {
    return value.toFixed(2);
  }

  return value.toFixed(5);
}

export function formatValueReplace(value) {
  value = String(value);
  value = value.replace(/,/g, "");
  value = parseFloat(value);

  return value;
}
