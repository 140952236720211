export const Dataset_standard_acc = () => {
  return {
    COMMODITIES: {
      "COPPER-F": ["4.3435", "4.3475", "0.00400", "40"],
      "SBEAN-F": ["9.7648", "9.7828", "0.01800", "180"],
      "USOil-F": ["70.852", "70.882", "0.03000", "30"],
      "UKOil-F": ["74.458", "74.493", "0.03500", "35"],
      "WHEAT-F": ["5.25", "5.27", "0.02000", "20"],
      NGAS: ["2.272", "2.285", "0.01300", "13"],
      USOUSD: ["70.852", "70.882", "0.03000", "30"],
    },
    FOREX: {
      AUDCAD: ["0.92086", "0.92105", "0.00019", "19"],
      AUDCHF: ["0.57963", "0.57975", "0.00012", "12"],
      AUDCNH: ["4.77441", "4.77484", "0.00043", "43"],
      AUDHKD: ["5.19945", "5.1999", "0.00045", "45"],
      AUDJPY: ["100.059", "100.07", "0.01100", "11"],
      AUDNZD: ["1.10371", "1.10395", "0.00024", "24"],
      AUDSGD: ["0.87877", "0.8789", "0.00013", "13"],
      AUDUSD: ["0.66906", "0.66917", "0.00011", "11"],
      CADCHF: ["0.6294", "0.6295", "0.00010", "10"],
      CADJPY: ["108.651", "108.661", "0.01000", "10"],
      CHFJPY: ["172.616", "172.627", "0.01100", "11"],
      EURAUD: ["1.6219", "1.62209", "0.00019", "19"],
      EURCAD: ["1.49371", "1.49382", "0.00011", "11"],
      EURCHF: ["0.94017", "0.94032", "0.00015", "15"],
      EURDKK: ["7.46049", "7.46083", "0.00034", "34"],
      EURGBP: ["0.83587", "0.83599", "0.00012", "12"],
      EURHUF: ["400.238", "400.292", "0.05400", "54"],
      EURJPY: ["162.301", "162.314", "0.01300", "13"],
      EURMXN: ["21.609", "21.6146", "0.00560", "56"],
      EURNOK: ["11.82298", "11.82436", "0.00138", "138"],
      EURNZD: ["1.79028", "1.7905", "0.00022", "22"],
      EURPLN: ["4.30053", "4.30221", "0.00168", "168"],
      EURSEK: ["11.38727", "11.38983", "0.00256", "256"],
      EURSGD: ["1.42539", "1.42552", "0.00013", "13"],
      EURUSD: ["1.08524", "1.08534", "0.00010", "10"],
      GBPAUD: ["1.94025", "1.94042", "0.00017", "17"],
      GBPCAD: ["1.78686", "1.78699", "0.00013", "13"],
      GBPCHF: ["1.12472", "1.12485", "0.00013", "13"],
      GBPJPY: ["194.151", "194.172", "0.02100", "21"],
      GBPNZD: ["2.14165", "2.14191", "0.00026", "26"],
      GBPUSD: ["1.29824", "1.29835", "0.00011", "11"],
      NZDCAD: ["0.83422", "0.83442", "0.00020", "20"],
      NZDCHF: ["0.5251", "0.52522", "0.00012", "12"],
      NZDJPY: ["90.648", "90.661", "0.01300", "13"],
      NZDUSD: ["0.60613", "0.60622", "0.00009", "9"],
      SGDJPY: ["113.858", "113.868", "0.01000", "10"],
      USDCAD: ["1.37641", "1.37651", "0.00010", "10"],
      USDCHF: ["0.8663", "0.8664", "0.00010", "10"],
      USDCNH: ["7.13551", "7.13596", "0.00045", "45"],
      USDDKK: ["6.87409", "6.87459", "0.00050", "50"],
      USDHKD: ["7.77085", "7.77108", "0.00023", "23"],
      USDHUF: ["369.599", "369.814", "0.21500", "215"],
      USDJPY: ["149.547", "149.556", "0.00900", "9"],
      USDMXN: ["19.93526", "19.93807", "0.00281", "281"],
      USDNOK: ["10.92851", "10.92959", "0.00108", "108"],
      USDPLN: ["3.96296", "3.96414", "0.00118", "118"],
      USDSEK: ["10.52622", "10.52752", "0.00130", "130"],
      USDSGD: ["1.31338", "1.31348", "0.00010", "10"],
      USDZAR: ["17.65929", "17.66331", "0.00402", "402"],
    },
    INDICES: {
      ASX200: ["8357.22", "8358.52", "1.30000", "130"],
      CAC40: ["7502.81", "7504.31", "1.50000", "150"],
      DAX40: ["19443.6", "19445.9", "2.30000", "230"],
      ESP35: ["11928.36", "11933.36", "5.00000", "500"],
      FTSE100: ["8347.9", "8349.1", "1.20000", "120"],
      HK50: ["20398.55", "20403.55", "5.00000", "500"],
      JP225: ["38993.55", "39000.55", "7.00000", "700"],
      NDX100: ["20139.09", "20140.29", "1.20000", "120"],
      UKOUSD: ["74.824", "74.854", "0.03000", "30"],
      US2000: ["2287.9", "2288.2", "0.30000", "30"],
      US500: ["5832.18", "5832.58", "0.40000", "40"],
      STOXX50: ["4904.1", "4905.7", "1.60000", "160"],
      VIX: ["20.76", "20.80", "0.04000", "40"],
      CHINA50: ["13304", "13314", "10.00000", "1000"],
      USDOLLAR: ["103.385", "103.425", "0.04000", "40"],
      WS30: ["34250.34", "34260.84", "10.50000", "1050"],
    },
  };
};

export const Dataset_plus_acc = () => {
  return {
    COMMODITIES: {
      "COPPER-F": ["4.3435", "4.3475", "0.00400", "40"],
      "SBEAN-F": ["9.7648", "9.7828", "0.01800", "180"],
      "UKOil-F": ["74.458", "74.493", "0.03500", "35"],
      "USOil-F": ["70.078", "70.113", "0.03500", "35"],
      "WHEAT-F": ["5.8513", "5.8613", "0.01000", "100"],
      NGAS: ["2.272", "2.285", "0.01300", "13"],
      USOUSD: ["70.852", "70.882", "0.03000", "30"],
    },
    FOREX: {
      AUDCAD: ["0.92090", "0.92101", "0.00011", "11"],
      AUDCHF: ["0.57967", "0.57971", "0.00004", "4"],
      AUDCNH: ["4.77445", "4.7748", "0.00035", "35"],
      AUDHKD: ["5.19949", "5.19986", "0.00037", "37"],
      AUDJPY: ["100.063", "100.066", "0.00300", "3"],
      AUDNZD: ["1.10375", "1.10391", "0.00016", "16"],
      AUDSGD: ["0.87881", "0.87886", "0.00005", "5"],
      AUDUSD: ["0.6691", "0.66913", "0.00003", "3"],
      CADCHF: ["0.62944", "0.62946", "0.00002", "2"],
      CADJPY: ["108.655", "108.657", "0.00200", "2"],
      CHFJPY: ["172.620", "172.623", "0.00300", "3"],
      EURAUD: ["1.62194", "1.62205", "0.00011", "11"],
      EURCAD: ["1.49375", "1.49378", "0.00003", "3"],
      EURCHF: ["0.94021", "0.94028", "0.00007", "7"],
      EURDKK: ["7.46053", "7.46079", "0.00026", "26"],
      EURGBP: ["0.83591", "0.83595", "0.00004", "4"],
      EURHUF: ["400.242", "400.288", "0.04600", "46"],
      EURJPY: ["162.305", "162.31", "0.00500", "5"],
      EURMXN: ["21.6094", "21.6142", "0.00480", "48"],
      EURNOK: ["11.82302", "11.82432", "0.00130", "130"],
      EURNZD: ["1.79032", "1.79046", "0.00014", "14"],
      EURPLN: ["4.30057", "4.30217", "0.00160", "160"],
      EURSEK: ["11.38731", "11.38979", "0.00248", "248"],
      EURSGD: ["1.42543", "1.42548", "0.00005", "5"],
      EURUSD: ["1.08528", "1.0853", "0.00002", "2"],
      GBPAUD: ["1.94029", "1.94038", "0.00009", "9"],
      GBPCAD: ["1.7869", "1.78695", "0.00005", "5"],
      GBPCHF: ["1.12476", "1.12481", "0.00005", "5"],
      GBPJPY: ["194.155", "194.168", "0.01300", "13"],
      GBPNZD: ["2.14169", "2.14187", "0.00018", "18"],
      GBPUSD: ["1.29828", "1.29831", "0.00003", "3"],
      NZDCAD: ["0.83426", "0.83438", "0.00012", "12"],
      NZDCHF: ["0.52514", "0.52518", "0.00004", "4"],
      NZDJPY: ["90.652", "90.657", "0.00500", "5"],
      NZDUSD: ["0.60617", "0.60618", "0.00001", "1"],
      SGDJPY: ["113.862", "113.864", "0.00200", "2"],
      USDCAD: ["1.37645", "1.37647", "0.00002", "2"],
      USDCHF: ["0.86634", "0.86636", "0.00002", "2"],
      USDCNH: ["7.13555", "7.13592", "0.00037", "37"],
      USDDKK: ["6.87413", "6.87455", "0.00042", "42"],
      USDHKD: ["7.77089", "7.77104", "0.00015", "15"],
      USDHUF: ["369.603", "369.81", "0.20700", "207"],
      USDJPY: ["149.551", "149.552", "0.00100", "1"],
      USDMXN: ["19.9353", "19.93803", "0.00273", "273"],
      USDNOK: ["10.92855", "10.92955", "0.00100", "100"],
      USDPLN: ["3.963", "3.9641", "0.00110", "110"],
      USDSEK: ["10.52626", "10.52748", "0.00122", "122"],
      USDSGD: ["1.31342", "1.31344", "0.00002", "2"],
      USDZAR: ["17.65933", "17.66327", "0.00394", "394"],
    },
    INDICES: {
      ASX200: ["8357.22", "8358.52", "1.30000", "130"],
      CAC40: ["7502.81", "7504.31", "1.50000", "150"],
      DAX40: ["19443.6", "19445.9", "2.30000", "230"],
      ESP35: ["11928.36", "11933.36", "5.00000", "500"],
      FTSE100: ["8347.9", "8349.1", "1.20000", "120"],
      HK50: ["20398.55", "20403.55", "5.00000", "500"],
      JP225: ["38993.55", "39000.55", "7.00000", "700"],
      NDX100: ["20139.09", "20140.29", "1.20000", "120"],
      UKOUSD: ["74.824", "74.854", "0.03000", "30"],
      US2000: ["2287.9", "2288.2", "0.30000", "30"],
      US500: ["5832.18", "5832.58", "0.40000", "40"],
      STOXX50: ["4904.1", "4905.7", "1.60000", "160"],
      VIX: ["20.76", "20.86", "0.10000", "10"],
      CHINA50: ["13304", "13314", "10.00000", "1000"],
      USDOLLAR: ["103.385", "103.425", "0.04000", "40"],
      WS30: ["43036.45", "43038.25", "1.80000", "180"],
    },
  };
};
